import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';

const getEnvironmentEndPoint = () => {
  //console.log(process.env.NODE_ENV);
  return (process.env.NODE_ENV === 'production') ? "https://acvro821o2.execute-api.ap-southeast-2.amazonaws.com/dev" :  "http://localhost:8080/local";
}




Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: "ap-southeast-2:d05b9b9a-6e69-43de-ba94-4410a112d9fe",
      // REQUIRED - Amazon Cognito Region
      mandatorySignIn: false,
      region: "ap-southeast-2",
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "ap-southeast-2_ZZgbJPU7T",
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "2k53cu0t2bstv5brt017br8jo8"
    },
    API: {
      endpoints: [
        {
          name: "dev-API",
          endpoint: getEnvironmentEndPoint(),
          region: "ap-southeast-2"
        }
      ]
    }
  });


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
