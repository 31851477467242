import React, { useState, useEffect,useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Paper,TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DeleteIcon from '@material-ui/icons/Delete';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';



import { AmplifySignOut } from '@aws-amplify/ui-react';
import { API,Auth } from 'aws-amplify';

import MappedTable from './components/MappedTable';
import TargetDialog from './components/TargetDialog';
import EditThemePADialog from './components/EditThemePADialog';

const sleep = (delayInms) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, delayInms);
    });
}

const retryIfFailed = async (apiName, path) => {

    let result;
    const retryCount = 5;
    for (let k = 0; k < retryCount; k++) {
        try {
            result = await API.get(apiName, path);
        }
        catch (err) {
            console.log(err);
        }
        if (result)
            return result;
        else {
            const waitTime = Math.pow(3, (k + 1));
            console.log(`Waiting ${waitTime} seconds before retry...`);
            await sleep(waitTime * 1000);
        }
    }
    throw new Error('All servers are busy please retry later!');
}


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Ethics Application No. 2019/301. Copyright © '}
            <Link color="inherit" href="https://www.uow.edu.au/smart/">
                SMART infrastructure facility, University of Wollongong and Healthy Cities Illawarra.
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap'
    },
    button: {
        margin: theme.spacing(1)
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        margin: theme.spacing(1)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: '#fff',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        margin: theme.spacing(4, 0, 2),
      }
}));

const footers = [
    //   {
    //     title: 'Company',
    //     description: ['Team', 'History', 'Contact us', 'Locations'],
    //   },
    //   {
    //     title: 'Features',
    //     description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
    //   },
    //   {
    //     title: 'Resources',
    //     description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
    //   },
    // {
    //     title: 'Legal',
    //     description: ['Privacy policy', 'Terms of use'],
    // },
];

export default function Home() {
    const [themes, setThemes] = useState([]);
    const [goals, setGoals] = useState([]);
    const [goalTargets, setGoalTargets] = useState([]);
    const [allIndicators, setAllIndicators] = useState([]);
    const [myMappings, setMyMappings] = useState([]);
    const [targetDlgOpen, setTargetDlgOpen] = useState(false);
    const [themeDlgOpen, setThemeDlgOpen] = useState(false);
    const [selectedPA, setSelectedPA] = useState({ id: 0 });
    const [selectedTheme, setSelectedTheme] = useState({ id: 0 });
    const [matchedMatrix, setMatchedMatrix] = useState({});
    //const [PALoading, setPALoading] = useState(false);
    const [newMapping, setNewMapping] = useState('');
    const [newCouncil, setNewCouncil] = useState('');
    const [lastSaved, setLastSaved] = useState();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [cCouncil, setCCouncil] = useState(0);
    const [cMapping, setCMapping] = useState(0);
    const [allCouncils, setAllCouncils] = useState([]);
    const mappedTableRef = useRef();
    const [councilDlgOpen, setCouncilDlgOpen] = useState(true);
    const [councilUsers, setCouncilUsers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const councs = await retryIfFailed('dev-API', '/client/councils');
                //console.log(councs);
                setAllCouncils(councs);
                if (councs.length >0)
                  setCCouncil(councs[0].id);
                const gls = await API.get('dev-API', '/client/sdggoals');
                //console.log(gls);
                setGoals(gls);
                const glsTargs = await API.get('dev-API', '/client/sdgtargets');
                //console.log(glsTargs);
                setGoalTargets(glsTargs);
                const indics = await API.get('dev-API', '/client/sdgindicators');
                //console.log(indics);
                setAllIndicators(indics);
            }
            catch (err) {
                alert(err.message);
                Auth.signOut();
            } finally {
                setLoading(false);
            }

        };
        const userConsent = localStorage.getItem('consented');
        if (userConsent !== 'yes') {
            if (window.confirm('I consent to data entered forming part of a wider study analysing local SDG mapping.'))
                localStorage.setItem('consented', 'yes');
            else {
                alert('You have declined the consent; signing you out.')
                Auth.signOut();
                return;
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await API.get('dev-API', '/client/cspthemespas', {
                    queryStringParameters: {
                        councilid: cCouncil,
                    }
                });
                //console.log(result);
                //setPALoading(false);
                setThemes(result);
                const mappings = await API.get('dev-API', '/client/mymappings',{queryStringParameters:{cid: cCouncil}});
                //console.log(mappings);
                setMyMappings(mappings);
                setCMapping(0);
                setMatchedMatrix({});
                const cusers = await API.get('dev-API', '/client/councilusers',{queryStringParameters:{cid: cCouncil}});
                const thisUser=await Auth.currentAuthenticatedUser();
                cusers.find((user) => (user.userid === thisUser.username))["UserAttributes"]= await Auth.userAttributes(thisUser);
                setCouncilUsers(cusers);
        
            }
            catch (err) {
                alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
            } finally {
                setLoading(false);
            }

        };
        if (cCouncil)
          fetchData();
    }, [cCouncil]);


    useEffect(()=>{
        const fetchData = async () => {    
            try {
                setLoading(true);
    
                const result = await API.get('dev-API', '/client/mapping', {
                    queryStringParameters: {
                        mid: cMapping,
                        cid: cCouncil
                    }
                });
                //console.log(result);
    
                const convertedBack = {};
                Object.keys(result).forEach((paId) => {
                    convertedBack[paId] = {};
                    result[paId].forEach((targHeading) => {
                        const targObj = goalTargets.find((tg) => (tg.heading === targHeading));
                        convertedBack[paId][targHeading] = { targ_heading: targObj.heading, targ_title: targObj.title };
                    });
                });
    
             setMatchedMatrix(convertedBack);
             setLastSaved(null);
    
            } catch (err) {
                alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
            } finally {
                setLoading(false);
            }
        };
        if ((cMapping>0)&&(cCouncil>0))
         fetchData();
// eslint-disable-next-line
    },[cMapping]); 

    const handleCouncilClose = async (event) => {
        if (!cMapping) {
            alert("Please select or create a mapping project first.");
            return;
        }

        setCouncilDlgOpen(false);
    };



    const handleCreateMapping = async (event) => {
        if ((!cCouncil) || (!newMapping)) {
            alert("Please select an organisation, and enter a new name first.");
            return;
        }

        try {
            setLoading(true);

            const result = await API.post('dev-API', '/client/mapping', {
                body: {
                    cid: cCouncil,
                    name: newMapping
                }
            });
            //console.log(result);

            setNewMapping('');

            setMyMappings([...myMappings,{id: result.id, name: result.name}]);

            setCMapping(result.id);
            setMatchedMatrix({});
            setLastSaved(null);
            alert('Mapping successfully created.');

        } catch (err) {
            alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
        } finally {
            setLoading(false);
        }

    }

    const handleCreateCouncil = async (event) => {
        if ((newCouncil.trim()).length<3) {
            alert("Please enter a valid new organisations name.");
            return;
        }

        try {
            setLoading(true);

            const result = await API.put('dev-API', '/client/council', {
                body: {
                    name: newCouncil
                }
            });
            //console.log(result);

            setNewCouncil('');
            setAllCouncils([...allCouncils, result]);
            setCCouncil(result.id);

            alert('Organisation successfully created.');

        } catch (err) {
            alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
        } finally {
            setLoading(false);
        }

    }

    const handleDeleteCouncil = async (event) => {
        try {
            setLoading(true);

            if (window.confirm('Are you sure you want to delete this organisation ? \n NOTE this action cannot be undone.')) {

                const result = await API.del('dev-API', '/client/council', {
                    body: {
                        cid: cCouncil
                    }
                });
                //console.log(result);
                
 
                setAllCouncils((currentCouncils) => {
                    const retVal=allCouncils.slice();
                    //console.log(retVal);
                    retVal.splice(allCouncils.findIndex((v) => (v.id === result.id)), 1);
                    //console.log(retVal);
                    return retVal;

                });
                setCCouncil(0);

                alert('Organisation successfully deleted.');
            }

        } catch (err) {
            console.log(err?.response?.data?.message);
            alert('Unable to delete; please make sure you are the organisation owner.');
        } finally {
            setLoading(false);
        }

    }


    const handleDeleteMapping = async (event) => {
        try {
            setLoading(true);

            if (window.confirm('Are you sure you want to delete all mappings in this project ? \nNOTE this action cannot be undone.')) {

                const result = await API.del('dev-API', '/client/mapping', {
                    body: {
                        cid: cCouncil,
                        mid: cMapping
                    }
                });
                //console.log(result);
                
 
                setMyMappings((currentMappings) => {
                    const retVal=currentMappings.slice();
                    // console.log(retVal);
                    retVal.splice(currentMappings.findIndex((v) => (v.id === result.id)), 1);
                    // console.log(retVal);
                    return retVal;

                });
                setCMapping(0);

                alert('Mapping successfully deleted.');
            }

        } catch (err) {
            console.log(err?.response?.data?.message);
            alert('Unable to delete; please make sure you are the organisation owner.');
        } finally {
            setLoading(false);
        }

    }
    

    const handleMappingSave = async () => {
        //console.log(matchedMatrix);
        if (!cMapping) {
            alert("Please select (or create) a mapping first. ");
            return;
        }
        try {
            setLoading(true);

            const convertedMapping = {};
            Object.keys(matchedMatrix).forEach((paId) => {
                convertedMapping[paId] = [];
                Object.keys(matchedMatrix[paId]).forEach((targHeading) => {
                    if (matchedMatrix[paId][targHeading])
                        convertedMapping[paId].push(targHeading);
                });
            });
            //console.log(convertedMapping);

            await API.put('dev-API', '/client/mapping', {
                body: {
                    cid: cCouncil,
                    mid: cMapping,
                    mapping: convertedMapping
                }
            });
            //console.log(result);
            setLastSaved(new Date());
        } catch (err) {
            alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
        } finally {
            setLoading(false);
        }

    }

    const handleExportCSV = async () => {
        try {
            if (!cMapping) {
                alert("Please select (or create) a mapping first.");
                return;
            }
            const data = await API.get('dev-API', `/client/exportmapping`, {
                queryStringParameters: {
                    mid: cMapping,
                    cid: cCouncil
                },
                responseType: 'text'
            });
            var aDomElem = document.createElement('a');
            aDomElem.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
            aDomElem.setAttribute('download', `Mapping_${cMapping}.csv`);
            aDomElem.style.display = 'none';
            document.body.appendChild(aDomElem);
            aDomElem.click();
            document.body.removeChild(aDomElem);
        } catch (err) {
            alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
        }
    }

    const handleEditThemeClick = () => {
        if (!cCouncil) {
            alert("Please select an organisation first.");
            return;
        }
        setThemeDlgOpen(true);
    }



    const handleTargetDialogClose = () => {

        setTargetDlgOpen(false);
    }

    const handlePASelect = (theme, pa) => {
        if (!cMapping)
        {
            alert("Please select (or create) a mapping first. ");
            return;
        }
        setSelectedTheme(theme);
        setSelectedPA(pa);
        setTargetDlgOpen(true);
    }

    const handleSignOut = ()=>
    {
        if (window.confirm('Are you sure you want to sign out?'))
        {
         Auth.signOut();
        }
    }





    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <img alt="Map CSPs to SDGs" src='img/logos/E_SDG_logo_without_UN_emblem_horizontal_CMYK_Transparent.png' style={{height:'50px', marginRight: '5px'}} />
                    <Typography variant="h6" noWrap className={classes.toolbarTitle}>
                        
                    </Typography>

                    <nav>
                        <Link variant="button" color="textPrimary" href="https://www.uow.edu.au/smart/" target='_blank' rel="noopener noreferrer" className={classes.link}>
                            Support
                        </Link>
                    </nav>
                    {/* <Button href="#" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button> */}
                    <AmplifySignOut />
                </Toolbar>
            </AppBar>
            {/* Hero unit */}
            <Container maxWidth="xl" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                    SDG Localiser
                </Typography>
                <Typography variant="h6" align="center" color="textSecondary" component="p">
                  This tool enables organisations to map their strategic plans to the UN Sustainable Development Goals.
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="xl" component="main">
                <Grid container spacing={5}>
                    <Grid container item sm={12} xs={12} spacing={2}>
                        <Grid item sm={12} xs={12} >

                            <Paper elevation={1} style={{ display: 'flex', padding: '5px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}  >
                                {cCouncil && <Typography variant="h5">
                                    {allCouncils.find((c) => (c.id === cCouncil)).name}
                                </Typography>}
                                {cMapping && <Grid item container style={{justifyContent:'center', alignItems:'center'}}>
                                    <Typography variant="subtitle1">
                                        Mapping project: {myMappings.find((m) => (m.id === cMapping))?.name + ' (#' + cMapping + ')'}
                                    </Typography>
                                    <Button disabled={cCouncil === 0} aria-label="Switch"
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        className={classes.button}
                                        startIcon={ <SwapHorizontalCircleIcon />}
                                        onClick={() => setCouncilDlgOpen(true)}>
                                       
                                        Change 
                                    </Button>
                                </Grid>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                 <Grid item container style={{flex:1, justifyContent: 'flex-start', alignItems:'center'}}>
                 <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={handleEditThemeClick}
                 >
                    Edit themes
                        </Button>
                </Grid>
                    {lastSaved && <Typography variant="subtitle2">Last saved on {lastSaved.toString()}</Typography>}
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleMappingSave}
                        startIcon={<SaveIcon />}
                    >
                        Save
                        </Button>
                    <ReactToPrint
                        trigger={() => (<Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={handleMappingSave}
                            startIcon={<PrintIcon />}
                        >
                            Print
                        </Button>)}
                        content={() => mappedTableRef.current}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleExportCSV}
                        startIcon={<GetAppIcon />}
                    >
                        Export (CSV)
                        </Button>
                </Grid>

                <div ref={mappedTableRef} className='printdiv' >
                    <MappedTable goals={goals} themePAs={themes} MappingMatrix={matchedMatrix} onPASelect={handlePASelect} />
                </div>

            </Container>

            {/* Footer */}
            <Container maxWidth="xl" component="footer" className={classes.footer}>
                <Box style={{ justifyContent: 'center', display: 'flex', marginTop: '10px' }}>
                    <img alt="SMART Infrastructure facility" src="img/logos/smart-logo.png" style={{ height: '70px', marginRight: '10px' }} />
                    <img alt="SMART Infrastructure facility" src="img/logos/SDG Wheel_Transparent_WEB.png" style={{ height: '70px', marginRight: '10px' }} />
                    <img alt="Healthy Cities Illawara" src="img/logos/HCI-white-bg.jpg" style={{ height: '70px' }} />
                </Box>
                <Grid container spacing={4} justify="flex-end">
                    {footers.map((footer) => (
                        <Grid item xs={6} sm={3} key={footer.title}>
                            <Typography variant="h6" color="textPrimary" >
                                {footer.title}
                            </Typography>
                            <ul>
                                {footer.description.map((item) => (
                                    <li key={item}>
                                        <Link href="#" variant="subtitle2" color="textSecondary">
                                            {item}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Box mt={5} >
                    <Copyright />
                </Box>
                <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={councilDlgOpen}
                onClose={handleCouncilClose}
                disableBackdropClick={true}
                aria-labelledby="select-council-dlg"
            >
                <DialogTitle id="select-council-dlg">Select or create an organisation and a mapping project ...</DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                        </DialogContentText>
                        <Grid container >
                            <Grid item container sm={8} xs={12}>
                                <Grid item container sm={12} style={{ alignItems: 'center' }}>
                                    <FormControl className={classes.formControl} style={{ flex: 8 }}>
                                        <InputLabel id="demo-simple-select-helper-label">Organisations</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={cCouncil}
                                            onChange={(ev) => setCCouncil(ev.target.value)}
                                        >
                                            <MenuItem disabled value={0}>
                                                <em>None</em>
                                            </MenuItem>
                                            {allCouncils.map((e, ind) => (<MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>))}
                                        </Select>
                                        <FormHelperText>Please select an organisation</FormHelperText>
                                    </FormControl>
                                    <IconButton disabled={cCouncil === 0} aria-label="delete" color="secondary" onClick={handleDeleteCouncil}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item container sm={12} style={{ alignItems: 'center' }}>
                                    <FormControl style={{ flex: 10 }}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            size="small"
                                            label="New organisations name..."
                                            value={newCouncil}
                                            onChange={(ev) => setNewCouncil(ev.target.value)}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    <IconButton aria-label="delete" color="primary" onClick={handleCreateCouncil}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item container sm={12} style={{ alignItems: 'center' }}>
                                    <FormControl className={classes.formControl} style={{ flex: 1 }} fullWidth>
                                        <InputLabel id="mapping-select-label">Mapping projects:</InputLabel>
                                        <Select
                                            labelId="mapping-select-label"
                                            id="mapping-select-helper"
                                            value={cMapping}
                                            onChange={(ev) => setCMapping(ev.target.value)}
                                        >
                                            <MenuItem disabled value={0}  >
                                                <em>None</em>
                                            </MenuItem>
                                            {myMappings.map((e, ind) => (<MenuItem key={e.id} value={e.id}>{e.name+ ' (#'+e.id+')'}</MenuItem>))}
                                        </Select>
                                        <FormHelperText>Please select a mapping</FormHelperText>
                                    </FormControl>
                                    <IconButton disabled={cMapping === 0} aria-label="delete" color="secondary" onClick={handleDeleteMapping} >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item container sm={12} style={{ alignItems: 'center' }}>
                                    <FormControl style={{ flex: 3 }}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="New mapping name..."
                                            size="small"
                                            value={newMapping}
                                            onChange={(ev) => setNewMapping(ev.target.value)}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    <IconButton aria-label="delete" color="primary" onClick={handleCreateMapping}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item container sm={12} style={{ alignItems: 'center' }}>

                                </Grid>

                            </Grid>
                            <Grid item container sm={4} xs={12} style={{flexDirection:'column'}}>
                                <Typography variant="h6" style={{ marginLeft: '1rem', marginTop:'1rem' }} >
                                    Organisation users:
                                </Typography>
                                <List style={{ width: '100%' }}>
                                    {councilUsers.map((u,ind) => (
                                        <ListItem key={ind}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <AccountBoxIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            {u.UserAttributes ? <ListItemText
                                                primary={u.UserAttributes.find((attr) => (attr.Name === 'given_name')).Value + ' ' + u.UserAttributes.find((attr) => (attr.Name === 'family_name')).Value}
                                                secondary={u.UserAttributes.find((attr) => (attr.Name === 'email')).Value}
                                                title={u.userid}
                                            /> : <ListItemText
                                            primary={'User '+ (ind+1)}
                                            secondary={u.userid} />}
                                            {/* <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction> */}
                                        </ListItem>

                                    ))}

                                </List>
                            </Grid>
                        </Grid>

                    </DialogContent>
                <DialogActions>
                <Button  variant="outlined" href="https://www.youtube.com/watch?v=OY5UxUjwk1E" target='_blank' rel="noopener noreferrer" color="primary" >
                    Help 
                  </Button>
                <Button onClick={handleSignOut} variant="outlined" color="secondary">
                        Sign out 
                  </Button>
                    <Button onClick={handleCouncilClose} variant="contained" color="primary">
                        Start 
                  </Button>
                </DialogActions>
            </Dialog>
            </Container>
            <TargetDialog open={targetDlgOpen} handleClose={handleTargetDialogClose} goals={goals} goalTargets={goalTargets} indics={allIndicators} selectedTheme={selectedTheme} selectedPA={selectedPA} matchedMatrix={matchedMatrix} setMatchedMatrix={setMatchedMatrix} cid={cCouncil}/>
            <EditThemePADialog open={themeDlgOpen} handleClose={()=>setThemeDlgOpen(false)} themes={themes} setThemes={setThemes} cid={cCouncil} />



            <Backdrop className={classes.backdrop} open={loading} >
                <CircularProgress color="inherit" />
                <span style={{marginTop: '1rem'}}>Initializing resources ...</span>
            </Backdrop>
            {/* End footer */}
        </React.Fragment>
    );
}