import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import { Typography, Grid,Box, TextField, FormControl } from '@material-ui/core';
import {API} from 'aws-amplify';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    colFlex: {
        flexDirection: 'column',
        padding: '8px 32px'
    },
    heading : {
        fontWeight: 'bold',
        textDecorationLine: 'underline'
    }
}));

const EditThemeHeading = ({ thm, setThemes }) => {
    const [editMode, setEditMode] = useState(false);
    const [cHeading, setCHeading] = useState(thm.heading);
    const [cTitle, setCTitle] = useState(thm.title);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();


    const handleSaveDef = async () => {
        try {
            setLoading(true);
            const result = await API.post('dev-API', '/client/theme', {
                body: {
                    tid: thm.id,
                    heading: cHeading,
                    title: cTitle
                }
            });

            //console.log(result);

            setThemes((curTheme) => {
                const themeCopy=curTheme.slice(),
                  thInd=themeCopy.findIndex((t)=>(t.id === result.id ));
                themeCopy[thInd]={...themeCopy[thInd], heading: result.heading , title: result.title};
               //console.log(themeCopy);
              return themeCopy;
            });
            setEditMode(false);


        } catch (err) {
            alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
        } finally {
            setLoading(false);
        }

    }

    const handleDelete = async () => {
        try {
            setLoading(true);
            const result = await API.del('dev-API', '/client/theme', {
                body: {
                    tid: thm.id,
                }
            });

            //console.log(result);

            setThemes((curTheme) => {
                const themeCopy=curTheme.slice(),
                  thInd=themeCopy.findIndex((t)=>(t.id === result.id ));
                themeCopy.splice(thInd,1);
               //console.log(themeCopy);
              return themeCopy;
            });


        } catch (err) {
            alert('Unable to delete. \nPlease make sure all the dependent entities are deleted first.');
        } finally {
            setLoading(false);
        }

    }

    return ((!editMode) ? (
        <React.Fragment>
        <Typography className={classes.heading}>
            {thm.heading + '. ' + thm.title}
            </Typography>
            <IconButton style={{padding:'0px 5px'}} onClick={(e)=>{e.stopPropagation(); setEditMode(true);}}>
                <EditIcon/> 
            </IconButton> 
            <IconButton style={{padding:'0px 5px'}} onClick={(e)=>{e.stopPropagation(); handleDelete();}}> 
            <DeleteForeverIcon /> 
            </IconButton>
        </React.Fragment>
    ) : (
         <Box style={{display:'flex', width:'100%'}}>
             <FormControl  style={{flex:1, marginRight:'1rem'}} >
                <TextField
                    required
                    label="Heading"
                    value={cHeading}
                    onChange={(ev) => setCHeading(ev.target.value)}
                    variant="outlined"
                />
            </FormControl>
            <FormControl  style={{flex:5}}  >
                <TextField
                    required
                    label="Title"
                    value={cTitle}
                    onChange={(ev) => setCTitle(ev.target.value)}
                    variant="outlined"
                />
            </FormControl>
            <IconButton style={{padding:'0px 5px', flexGrow:0}} disabled={loading} onClick={(e)=>{e.stopPropagation(); handleSaveDef();}}>
                <DoneIcon />
            </IconButton>

         </Box>
        
    )

    );
}


const EditDefsInput = ({ pa, setThemes }) => {
    const [editMode, setEditMode] = useState(false);
    const [cDef, setCDef] = useState(pa.definition);
    const [cHeading, setCHeading] = useState(pa.heading);
    const [cTitle, setCTitle] = useState(pa.title);
    const [loading, setLoading] = useState(false);


    const handleSaveDef = async () => {
        try {
            setLoading(true);
            const result = await API.post('dev-API', '/client/pa', {
                body: {
                    paid: pa.id,
                    heading: cHeading,
                    title: cTitle,
                    definitions: cDef
                }
            });

            //console.log(result);

            setThemes((curTheme) => {
                const themeCopy=curTheme.slice(),
                  thInd=themeCopy.findIndex((t)=>(t.id === result.theme_id )),
                  themePAArrayCopy= themeCopy[thInd].pas.slice(),
                  paind=themePAArrayCopy.findIndex((par) => (par.id === result.id));
                themePAArrayCopy[paind]=result;
                themeCopy[thInd]={...themeCopy[thInd], pas:themePAArrayCopy};
               //console.log(themeCopy);
              return themeCopy;
            });
            setEditMode(false);


        } catch (err) {
            alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
        } finally {
            setLoading(false);
        }

    }

    const handleDelete = async () => {
        try {
            setLoading(true);
            const result = await API.del('dev-API', '/client/pa', {
                body: {
                    paid: pa.id
                }
            });

            //console.log(result);

            setThemes((curTheme) => {
                const themeCopy=curTheme.slice(),
                  thInd=themeCopy.findIndex((t)=>(t.id === result.theme_id )),
                  themePAArrayCopy= themeCopy[thInd].pas.slice(),
                  paind=themePAArrayCopy.findIndex((par) => (par.id === result.id));
                themePAArrayCopy.splice(paind,1);
                themeCopy[thInd]={...themeCopy[thInd], pas:themePAArrayCopy};
               //console.log(themeCopy);
              return themeCopy;
            });


        } catch (err) {
            alert('Unable to delete. \nPlease make sure all the dependent entities are deleted first.');
        } finally {
            setLoading(false);
        }

    }

    return ((!editMode) ? (
        <Accordion defaultExpanded={false} elevation={3}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
            >
                <Typography>
                    {pa.heading + ' ' + pa.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexWrap: 'wrap', justifyContent: 'felx-start' }}>
                <Box>
                    <Box>
                        {pa.definition && pa.definition.split(';').map((el, ind) => (<Chip key={ind} label={el} color="primary" clickable variant="outlined" style={{ marginRight: '5px', marginBottom: '5px' }} />))}
                    </Box>
                    <Button variant="contained" color="primary" onClick={() => setEditMode((em) => (!em))} style={{marginRight:'1rem'}}>Edit</Button>
                    <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
                </Box>
            </AccordionDetails>
        </Accordion>

    ) : (
        <Accordion defaultExpanded={false} elevation={3}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            style={{display:'flex'}}
        >
            <FormControl  style={{flex:1, marginRight:'1rem'}} >
                <TextField
                    required
                    label="Heading"
                    value={cHeading}
                    onChange={(ev) => setCHeading(ev.target.value)}
                    variant="outlined"
                />
            </FormControl>
            <FormControl  style={{flex:5}}  >
                <TextField
                    required
                    label="Title"
                    value={cTitle}
                    onChange={(ev) => setCTitle(ev.target.value)}
                    variant="outlined"
                />
            </FormControl>
        </AccordionSummary>
        <AccordionDetails style={{ flexWrap: 'wrap', justifyContent: 'felx-start' }}>
            <FormControl fullWidth>
                <TextField
                    required
                    fullWidth
                    label="Enter keywords separated by semicolon..."
                    value={cDef}
                    onChange={(ev) => setCDef(ev.target.value)}
                    variant="outlined"
                    multiline
                    rows={3}
                    style={{marginBottom:'1rem'}}
                />
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleSaveDef} disabled={loading}>Save</Button>
            </AccordionDetails>
        </Accordion>
    )

    );
}


const NewPriorityArea = ({ tid, setThemes }) => {
    const [newDef, setNewDef] = useState('');
    const [newHeading, setNewHeading] = useState('');
    const [newTitle, setNewTitle] = useState('');
    const [loading, setLoading] = useState(false);

    const handleAddNew = async () => {
        try {
            setLoading(true);
            const result = await API.put('dev-API', '/client/pa', {
                body: {
                    heading: newHeading,
                    title: newTitle,
                    definitions: newDef,
                    tid: tid
                }
            });

            //console.log(result);

            setThemes((curTheme) => {
                const themeCopy=curTheme.slice(),
                  thInd=themeCopy.findIndex((t)=>(t.id === result.theme_id )),
                  themePAArrayCopy= themeCopy[thInd].pas.slice();
                themePAArrayCopy.push(result);
                themeCopy[thInd]={...themeCopy[thInd], pas:themePAArrayCopy};
               //console.log(themeCopy);
              return themeCopy;
            });

            setNewHeading('');
            setNewDef('');
            setNewTitle('');


        } catch (err) {
            alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
        } finally {
            setLoading(false);
        }

    }

    return (<Accordion defaultExpanded={false} elevation={3}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            style={{ display: 'flex' }}
        >
            <AddCircleOutlineIcon color='primary' />
            <Typography>
                Add new priority area...
            </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexWrap: 'wrap', justifyContent: 'felx-start' }}>
            <Box style={{display:'flex', marginBottom:'1rem', width:'100%'}}>
        <FormControl style={{ flex: 1, marginRight: '1rem' }} >
            <TextField
                required
                label="Heading"
                value={newHeading}
                onChange={(ev) => setNewHeading(ev.target.value)}
                variant="outlined"
            />
        </FormControl>
        <FormControl style={{ flex: 5 }}  >
            <TextField
                required
                label="Title"
                value={newTitle}
                onChange={(ev) => setNewTitle(ev.target.value)}
                variant="outlined"
            />
        </FormControl>
        </Box>
        
            <FormControl fullWidth>
                <TextField
                    required
                    fullWidth
                    label="Enter keywords separated by semicolon..."
                    value={newDef}
                    onChange={(ev) => setNewDef(ev.target.value)}
                    variant="outlined"
                    multiline
                    rows={3}
                    style={{ marginBottom: '1rem' }}
                />
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleAddNew} disabled={loading}>Add</Button>
        </AccordionDetails>
    </Accordion>);

}

const NewThemeInput = ({ cid, setThemes }) => {
    const [newHeading, setNewHeading] = useState('');
    const [newTitle, setNewTitle] = useState('');
    const [loading, setLoading] = useState(false);

    const handleAddNew = async () => {
        try {
            setLoading(true);
            const result = await API.put('dev-API', '/client/theme', {
                body: {
                    heading: newHeading,
                    title: newTitle,
                    cid: cid
                }
            });

            //console.log(result);

            setThemes((curTheme) => ([...curTheme,{...result, pas:[]}]));

            setNewHeading('');
            setNewTitle('');


        } catch (err) {
            alert('An Error occured: ' + err.message + '.\nPlease make sure you are connected to internet.');
        } finally {
            setLoading(false);
        }

    }

    return (<Accordion defaultExpanded={false} elevation={3}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            style={{ display: 'flex' }}
        >
            <AddCircleOutlineIcon color='primary' />
            <Typography>
                Add a new theme
            </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexWrap: 'wrap', justifyContent: 'felx-start' }}>
            <Box style={{display:'flex', marginBottom:'1rem', width:'100%'}}>
        <FormControl style={{ flex: 1, marginRight: '1rem' }} >
            <TextField
                required
                label="Heading"
                value={newHeading}
                onChange={(ev) => setNewHeading(ev.target.value)}
                variant="outlined"
            />
        </FormControl>
        <FormControl style={{ flex: 5 }}  >
            <TextField
                required
                label="Title"
                value={newTitle}
                onChange={(ev) => setNewTitle(ev.target.value)}
                variant="outlined"
            />
        </FormControl>
        </Box>
        
            <Button variant="contained" color="primary" onClick={handleAddNew} disabled={loading}>Add</Button>
        </AccordionDetails>
    </Accordion>);

};

export default function EditThemePADialog({ open, handleClose, themes, setThemes, cid }) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={handleClose}
                aria-labelledby="csptarget-dialog-title"
            >
                <DialogTitle id="csptarget-dialog-title">Edit themes and priority areas</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <Grid container >
                        <Grid item xs={12}>
                        {!themes && <>
                            <LinearProgress style={{ marginBottom: '10px' }} />
                            <LinearProgress color="secondary" />
                        </>
                        }
                            {themes.map((thm, ind) => (
                                <Accordion key={ind} defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                      <EditThemeHeading thm={thm} setThemes={setThemes} /> 
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.colFlex}>
                                        {thm.pas && thm.pas.map((pa, paind) => (
                                            <EditDefsInput key={paind} pa={pa} setThemes={setThemes} />
                                        ))}
                                        <NewPriorityArea tid={thm.id} setThemes={setThemes} />
                                    </AccordionDetails>
                                </Accordion>

                        ))}
                        <NewThemeInput cid={cid} setThemes={setThemes} />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="secondary">
                        Cancel
                  </Button>
                    <Button onClick={() => handleClose()} variant="contained" color="primary">
                        Done
                  </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}