import './App.css';
import React from 'react';
import { Auth } from 'aws-amplify';
import Home from './Home';

import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, AmplifyConfirmSignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

const LoginUI = () => (
  <div className="AuthContainer" >
    <AmplifyAuthenticator usernameAlias='email' className="AuthenticatorContainer" >
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            label: "Email address",
            placeholder: "Enter your email address...",
            required: true,
          },
          {
            type: "given_name",
            label: "Given name(s)",
            placeholder: "Enter given name(s) here...",
            required: true,
          },
          {
            type: "family_name",
            label: "Surname",
            placeholder: "Enter surname here...",
            required: true,
          },
          {
            type: "password",
            label: "Password",
            placeholder: "Please choose a password...",
            required: true,
          }
        ]}
      />
      <AmplifySignIn slot="sign-in" usernameAlias='email' hideSignUp={false} headerText='Welcome to SDG Localiser - please sign in'></AmplifySignIn>
      <AmplifyConfirmSignUp usernameAlias='email' headerText="Please check your email for verification code" slot="confirm-sign-up"></AmplifyConfirmSignUp>
    </AmplifyAuthenticator>
    <h5>This tool is supported by funding from a Partnership Grant between University of Wollongong and Healthy Cities Illawarra.</h5>
  </div>);


function App() {
  const [authState, setAuthState] = React.useState();
  //const [user, setUser] = React.useState();


  React.useEffect(() => {

    if (authState === undefined) {
      Auth.currentAuthenticatedUser().then(authData => {
        setAuthState(AuthState.SignedIn);
        //setUser(authData);
      }).catch(() => { });
    }

    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      //setUser(authData)
    });
  });


  return (
    authState === AuthState.SignedIn ?
      (<div className="App">
        <Home/>
      </div>) : <LoginUI />
  );

}

export default App;
