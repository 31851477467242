import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import { Paper, Typography, Grid,Box } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { API } from 'aws-amplify';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

const a11yProps = (index) => ({
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    });

  


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

export default function TargetDialog({ open, handleClose, goals, goalTargets, indics, selectedTheme, selectedPA, matchedMatrix, setMatchedMatrix, cid  }) {
    const [selectedGoal, setSelectedGoal] = useState(0);
    const [matchingTargs, setMatchingTargs] = useState([]);
    const [targLoading, setTargLoading] = useState(false);
    const [tabValue, setTabValue] = React.useState(0);

    const classes = useStyles();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleTargetSelect = (event) => {
       // console.log(event.target.name, event.target.checked);
        setMatchedMatrix((prevMatrix) => {
            const newTarget = event.target.checked ? goalTargets.find((val) => (val.heading === event.target.name))  : false;
            const newMatrix = { ...prevMatrix, [selectedPA.id]: { ...(prevMatrix[selectedPA.id]), [event.target.name]: (newTarget ? {targ_heading: newTarget.heading, targ_title:newTarget.title} : false) } };
          //  console.log(newMatrix);
            return newMatrix;
        });

    }

    React.useEffect(() => {
        const loadNLPMatchings = async () => {
            try {
                if (selectedPA.id > 0) {
                    setTargLoading(true);
                    const result = await API.get('dev-API', '/client/matchingtargets', {
                        queryStringParameters: {
                            paid: selectedPA.id,
                            cid: cid
                        },
                    });

                   // console.log(result);
                    setTargLoading(false);
                    setMatchingTargs(result);
                }
            } catch (err) {
                console.log(err);
            }
        };
        loadNLPMatchings();
    }, [selectedPA,cid]);

    

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={handleClose}
                aria-labelledby="sdgtarget-dialog-title"
            >
                <DialogTitle id="sdgtarget-dialog-title">Select target(s) for priority area {selectedPA.heading || ''}</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={4}  >
                            {selectedPA.id &&
                                (<Paper key={selectedPA.heading} style={{ padding: 10 }}>
                                    <Typography style={{fontWeight:'bold'}}>{selectedTheme.heading + ' ' + selectedTheme.title} </Typography>
                                    <Typography style={{ marginLeft: '10px' }}>{selectedPA.heading + ' ' + selectedPA.title} </Typography>
                                    <Paper>

                                        <AccordionDetails style={{ flexWrap: 'wrap', justifyContent: 'felx-start' }}>
                                            {selectedPA.definition && selectedPA.definition.split(/\s*;\s*/g).map((el, ind) => (<Chip key={ind} label={el} color="primary" clickable variant="outlined" style={{ marginRight: '5px', marginBottom: '5px' }} />))}
                                        </AccordionDetails>
                                    </Paper>
                                </Paper>)}

                        </Grid>
                        <Grid item xs={12} sm={8} >
                            <Paper style={{ padding: 10}}>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>Mapped to targets: </Typography>
                                {matchedMatrix[selectedPA.id] ? Object.keys(matchedMatrix[selectedPA.id]).map((trgKey, ind) => (
                                    (matchedMatrix[selectedPA.id][trgKey]) &&
                                    (<Chip key={ind} style={{marginRight: '5px'}} label={<b>{matchedMatrix[selectedPA.id][trgKey].targ_heading}</b>}  color="primary" />)
                                )) : 'None'}
                            </Paper>

                            

                            
                            <Box>

                                <Paper style={{padding:'10px', marginTop:'10px'}} elevation={2}>
                                    <Tabs
                                        value={tabValue}
                                        indicatorColor="primary"
                                        variant="fullWidth"
                                        textColor="primary"
                                        onChange={handleTabChange}
                                        aria-label="Mapping mode tabs"
                                    >
                                        <Tab label="Manual" {...a11yProps(0)} />
                                        <Tab label="Automated assitance (beta)" {...a11yProps(1)} />
                                    </Tabs>
                                    <TabPanel value={tabValue} index={0}>
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel id="goals-select-helper-label">Goals</InputLabel>
                                            <Select
                                                labelId="goals-select-helper-label"
                                                id="goals-select-helper"
                                                value={selectedGoal}
                                                onChange={(ev) => setSelectedGoal(ev.target.value)}
                                            >
                                                <MenuItem disabled value={0}>
                                                    <em>None</em>
                                                </MenuItem>
                                                {goals.map((e, ind) => (<MenuItem key={'g'+ind} value={e.id}>
                                                    <img alt={'Goal '+e.id} src={'img/Inverted_Icons_WEB/E Inverted Icons_WEB-'+('0'+e.id).substr(-2)+'.png'} style={{height:'35px',marginRight:'5px'}}/>
                                                    {e.id + '. ' + e.title}
                                                    </MenuItem>))}
                                            </Select>
                                            <FormHelperText>Select a goal</FormHelperText>
                                        </FormControl>
                                        <Box style={{ height: '50vh', overflowY: 'scroll' }}>
                                            {goalTargets.filter((val) => (val.goal_id === selectedGoal)).map((e, ind) => (
                                                // <MenuItem key={e.heading} value={e.heading}>{e.heading + '. ' + e.title}</MenuItem>
                                                <Accordion key={ind}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-label="Expand"
                                                        aria-controls="additional-actions-content"
                                                        id={"additional-actions-header" + e.heading}
                                                    >
                                                        <FormControlLabel
                                                            aria-label="Target"
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={<Checkbox checked={matchedMatrix[selectedPA.id] ? (!!(matchedMatrix[selectedPA.id][e.heading])) : false} onChange={handleTargetSelect} name={e.heading} />}
                                                            label={<Typography style={{ textOverflow: 'ellipsis' }}><b>{e.heading}</b> {' ' + e.title}</Typography>}
                                                        />

                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ flexWrap: 'wrap', justifyContent: 'felx-start',flexDirection:'column' }}>
                                                        {/* <Typography variant="subtitle2" style={{marginTop:'5px', textDecoration:'underline'}}>Description:</Typography>
                                                        {e.title + '.'} */}
                                                        <Typography variant="subtitle2" style={{marginTop:'10px', textDecoration:'underline'}}>Target indicators:</Typography>
                                                        <List className={classes.root}>
                                                            {indics.filter((indic) => (indic.target_id === e.heading)).map((indic) => (
                                                                <ListItem key={indic.heading}>
                                                                    <ListItemAvatar>
                                                                        <Avatar>
                                                                            <AssessmentIcon />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={indic.heading} secondary={indic.description} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))
                                            }
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>
                                        <Paper style={{ padding: 10 }}> {/*NLP section*/}
                                            {targLoading && <>
                                                <LinearProgress style={{ marginBottom: '10px' }} />
                                                <LinearProgress color="secondary" />
                                            </>
                                            }
                                            {matchingTargs.map((mt, ind) => (
                                                <Accordion key={ind}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-label="Expand"
                                                        aria-controls="additional-actions1-content"
                                                        id="additional-actions1-header"
                                                    >
                                                        <FormControlLabel
                                                            aria-label="Target"
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={<Checkbox checked={matchedMatrix[selectedPA.id] ? (!!(matchedMatrix[selectedPA.id][mt.targ_heading])) : false} onChange={handleTargetSelect} name={mt.targ_heading} />}
                                                            label={<><b>{mt.targ_heading}</b> {' ' + mt.targ_title+ ' '}<Chip label={mt.total_sim.toFixed(3)} color="default" size="small"/></>}
                                                        />

                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ flexWrap: 'wrap', justifyContent: 'felx-start' }}>
                                                        {/* {mt.targ_title + '.'} */}
                                                        <Typography variant="subtitle2" style={{marginTop:'10px', textDecoration:'underline'}}>Target indicators:</Typography>
                                                        <List className={classes.root}>
                                                            {indics.filter((indic) => (indic.target_id === mt.targ_heading)).map((indic) => (
                                                                <ListItem key={indic.heading}>
                                                                    <ListItemAvatar>
                                                                        <Avatar>
                                                                            <AssessmentIcon />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={indic.heading} secondary={indic.description} />
                                                                </ListItem>
                                                            ))}
                                                        </List>


                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </Paper>
                                    </TabPanel>
                                </Paper>





                            {/* <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="goalstarget-select-helper-label">Targets</InputLabel>
                                <Select
                                    labelId="goalstarget-select-helper-label"
                                    id="goalstarget-select-helper"
                                    value={selectedTarget}
                                    onChange={(ev) => setSelectedTarget(ev.target.value)}
                                >
                                    <MenuItem disabled value={0}>
                                        <em>None</em>
                                    </MenuItem>
                                    {goalTargets.filter((val) => (val.goal_id === selectedGoal)).map((e, ind) => (<MenuItem key={e.heading} value={e.heading}>{e.heading + '. ' + e.title}</MenuItem>))}
                                </Select>
                                <FormHelperText>Please select a target</FormHelperText>
                            </FormControl>
                            <Paper style={{ padding: 10 }}>
                                <Typography variant='h6' color="textSecondary" gutterBottom>Indicators</Typography>
                                <List className={classes.root}>
                                    {selectedTarget && indics.filter((indic) => (indic.target_id === selectedTarget)).map((indic) => (
                                        <ListItem key={indic.heading}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <AssessmentIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={indic.heading} secondary={indic.description} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper> */}
                            </Box>
                        </Grid>

                    </Grid>



                </DialogContent>
                <DialogActions>
                {/* <Button onClick={() => handleClose(false)} color="default">
                        Cancel
                  </Button> */}
                    <Button onClick={() => handleClose()} variant="contained" color="primary">
                        Done
                  </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}