import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
  low_padding: {
      padding: '2px',
      width: '35px',
      //border: '1px dashed black'
  }
});



const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);


const SDGLogo = React.forwardRef(({goalID, ...props},ref) => (<img  alt={"SDG Goal "+goalID} ref={ref} {...props} src={'img/SDG_Icons_WEB/E-WEB-Goal-'+('0'+goalID).substr(-2)+'.png'} style={{width:'45px'}} />));




const isMappedToThisGoal = (mappingArray,goal_id) =>
{
    if (!mappingArray)
      return false;

    const allTargets=Object.keys(mappingArray);
    //console.log(allTargets);
    return !!(allTargets.find((trgHeading)=>(!!(mappingArray[trgHeading]) && trgHeading.split('.')[0]===(''+goal_id))));
}

const thisGoalMappedTargets = (mappingArray,goal_id) =>
{
    if (!mappingArray)
      return [];
    const allTargets=Object.keys(mappingArray);
    return (allTargets.filter((trgHeading)=>(trgHeading.split('.')[0]===(''+goal_id))));
}

export default function MappedTable({goals, themePAs, MappingMatrix, onPASelect}) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" >
        <TableHead >
          <TableRow>
            <TableCell><b>Themes and Priority Areas</b></TableCell>
            {goals.map((gl)=>(
              <TableCell key={'g'+gl.id} className={classes.low_padding} align="center" ><SDGLogo goalID={gl.id} /></TableCell>
            ))}
          </TableRow>
        </TableHead>
              <TableBody>
                  {themePAs.map((theme) => (
                      <React.Fragment  key={theme.id}>
                          <TableRow>

                              <TableCell style={{width:'10vw'}} component="th" scope="row">
                                  <b>{theme.heading + '. '+theme.title}</b>
                              </TableCell>
                              {goals.map((el) => (<TableCell key={'gl'+el.id} align="center"></TableCell>))}

                          </TableRow>
                          {theme.pas.map((pa) => (
                              <TableRow hover key={'pa'+pa.heading} onClick={()=>onPASelect(theme,pa)} style={{cursor: 'pointer'}}>
                                  <TableCell component="td" scope="row">
                                      <span style={{marginLeft: '10px'}}>{pa.heading + ' ' + pa.title} </span>
                                  </TableCell>
                                  {goals.map((gl) => (<TableCell key={pa.heading+''+gl.id} align="center" className={classes.low_padding}>{isMappedToThisGoal(MappingMatrix[pa.id], gl.id) ? (<HtmlTooltip key={'tt'+pa.heading+''+gl.id}  title={
                                      <React.Fragment>
                                          <Typography color="inherit" ><u>Mapped targets:</u></Typography>
                                          <ul>
                                          {thisGoalMappedTargets(MappingMatrix[pa.id],gl.id).map((trg,ind) => (MappingMatrix[pa.id][trg] && <li key={ind}><b>{MappingMatrix[pa.id][trg].targ_heading}:</b><em>{MappingMatrix[pa.id][trg].targ_title}</em></li>))}
                                          </ul>
                                      </React.Fragment>
                                  }
                                  ><SDGLogo goalID={gl.id} /></HtmlTooltip>) : ''}</TableCell>))}
                              </TableRow>
                          ))}
                      </React.Fragment>
                  ))}
              </TableBody>
      </Table>
    </TableContainer>
  );
}